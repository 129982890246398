/* ─────────────────────────────────────────────
                헤더 겸 네비게이션
───────────────────────────────────────────── */

/* Header container 스타일 */
.header-container {
    position: fixed;
    top: 30px;
    right: 30px;
    width: 60px;
    height: 60px;
    z-index: 10000;
    background: white;
    /* background: var(--color-smoothgreen); */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    transition: height 0.35s cubic-bezier(0.84, -0.48, 0.14, 1.5),
        transform 0.2s ease;
}

.header-container:hover {
    transform: scale(1.1);
}

.header-container.open {
    height: 280px;
}

.header-container.open:hover {
    transform: scale(1);
}

.header-trigger {
    padding: 15px;
    cursor: pointer;
}

/* 메뉴 햄버거 버튼 연출 */
.hamburger {
    position: relative;
    display: block;
    width: 30px;
    height: 26px;
    margin: 2px 0;
}

.hamburger .line {
    position: absolute;
    display: block;
    width: 100%;
    height: 4.56px;
    border-radius: 30px;
    background: var(--color-black);
    transition: all 0.35s cubic-bezier(0.84, -0.48, 0.14, 1.5);
    z-index: 2;
}

.hamburger .line:nth-child(1) {
    top: 0px;
}

.hamburger .line:nth-child(2) {
    top: 50%;
    width: 80%;
    transform: translateY(-50%);
}

.hamburger .line:nth-child(3) {
    bottom: 0px;
}

.rotate-down {
    top: 50% !important;
    transform: translateY(-50%) rotate(45deg);
}

.fade-out {
    width: 0px !important;
}

.rotate-up {
    bottom: 50% !important;
    transform: translateY(50%) rotate(-45deg);
}

/* 메뉴 아이템 컨테이너 */
.header-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.header-items button {
    padding: 0;
    background: none;
}

/* 메뉴 아이템 스타일 */
.header-item {
    transition: background-color 0.2s ease;
    background: var(--color-lgray);
    padding: 7px;
    border-radius: 10px;
    cursor: pointer;
}

.icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    stroke: var(--color-black);
    stroke-width: 0.24;
}

.header-item:hover {
    background: var(--color-smoothgreen-light);
}

.header-item.active {
    background: var(--color-smoothgreen-light);
}

/* ─────────────────────────────────────────────
                  트랜지션
───────────────────────────────────────────── */
.transition {
    position: fixed;
    z-index: 9999;
    transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    transform-origin: center center; /* 중앙을 기준으로 애니메이션 적용 */
}

.transition-rect-1 {
    background-color: #85d1cf;
}

.transition-rect-2 {
    background-color: #89e8bc;
}

.transition-rect-3 {
    background-color: #82de89;
}

.transition-rect-4 {
    background-color: var(--color-deepgreen);
}

/* ─────────────────────────────────────────────
                  메인 페이지
───────────────────────────────────────────── */

/* 임시, 나중에 별도로 설정해야할지도 모름 */
body {
    background: var(--color-deepgreen);
}

.main-wrapper {
    width: 100dvw;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
}

/* 최상단 로고 */
.logo img {
    width: 18em;
    -webkit-animation-name: logo;
    animation-name: logo;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-anim1ation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    margin-bottom: 1em;
}

/* 최상단 로고 애니메이션 없음 */
.logo-no-animation img {
    width: 18em;
    margin-bottom: 1em;
}

/* 제목, 접속 인원수 표시 */
.title-wrapper {
    display: flex;
    flex-direction: column;
    padding: 40px;
    color: white;
    text-align: center;
    border-radius: 20px;
    line-height: 27px;
}

/* 제목 영역 */
.title {
    font-weight: bold;
    color: white;
    font-size: 1.8em;
    margin-bottom: 5px;
    line-height: 1.2em;
}

/* 부제목 영역 */
.subtitle {
    margin-top: 0.8em;
    color: var(--color-mgray);
    font-size: 1em;
    transition: color 0.3s ease;
}

.subtitle:hover {
    color: var(--color-lightgreen);
}

.floor {
    background: var(--color-lightgreen);
    border-radius: 30px;
    width: 100%;
    height: 4px;
}

#position {
    width: 95%;
    padding-bottom: 8dvh;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.position-setter {
    position: absolute;
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: relative;
}

/* ─────────────────────────────────────────────
                works 페이지
───────────────────────────────────────────── */

#works-list {
    padding: 50px;
    overflow-y: auto;
    border-radius: 25px;
    height: 100dvh;
}

.works-list-grid {
    grid-gap: var(--works-gap);
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    display: grid;
}

.works-list-grid.dimmed .works:not(.active):not(:hover) {
    filter: brightness(0.3);
    pointer-events: none;
}

.works-list-grid.dimmed .works:not(.active):not(:hover) .glitch {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: none;
    opacity: 0.2;
    width: 100%;
    height: 100%;
    background:
        repeating-radial-gradient(#000 0 0.0001%, #fff 0 0.0002%) 50% 0/1000px 1000px,
        repeating-conic-gradient(#000 0 0.0001%, #fff 0 0.0002%) 60% 60%/1000px 1000px;
    background-blend-mode: difference;
    animation: b .2s infinite alternate;
}

.works-line {
    grid-gap: var(--works-gap);
    align-items: flex-start;
    display: grid;
    position: relative;
}

.works {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, opacity 0.3s ease, filter 0.3s ease;
}

.works:hover,
.works.active {
    transform: scale(1.05);
}

.works:hover .work-title
.works:hover .work-year {
    top: 10px;
    bottom: unset;
}

.work-title {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    font-weight: 400;
    font-size: 0.8em;
    padding: 4px 8px;
    border-radius: 5px;
    z-index: 2;
    pointer-events: none;
    white-space: nowrap;
}

.work-year {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    font-size: 0.8em;
    padding: 4px 8px;
    border-radius: 5px;
    z-index: 2;
    pointer-events: none;
    white-space: nowrap;
}

.work-icons {
    position: absolute;
    left: 15px;
    bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    opacity: 0;
    transition: all 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: 2;
}

.work-type {
    color: #fff;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease, transform 0.2s ease;
    font-size: 14px;
}

#Study {
    background: #3748e2;
}

#Release {
    background: #278936;
}

.work-icon {
    width: 32px;
    height: 32px;
    text-decoration: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease, transform 0.2s ease;
    padding: 5px;
    background: white;
}

.work-icon:hover {
    transform: scale(1.1);
}

.github {
    font-size: 24px;
}

.link {
    font-size: 20px;
}

.gradient-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent);
    height: 30%;
    transition: transform 0.5s ease, opacity 0.3s ease;
    opacity: 0;
    z-index: 1;
    transform: translateY(100%);
}

.works:hover .gradient-bg {
    transform: translateY(0);
    opacity: 1;
}

.drop-icon {
    opacity: 0;
    transform: translateY(-20px);
    animation: dropIn 0.3s forwards ease;
    animation-delay: var(--icon-delay, 0s);
}

.drop-icon:nth-child(1) {
    --icon-delay: 0.1s;
}

.drop-icon:nth-child(2) {
    --icon-delay: 0.15s;
}

.drop-icon:nth-child(3) {
    --icon-delay: 0.2s;
}

.drop-icon:nth-child(4) {
    --icon-delay: 0.25s;
}

@keyframes dropIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes b {
    100% {
        background-position: 50% 0, 60% 50%
    }
}

.works.focused .glitch-layer {
    display: none;
}

/* ─────────────────────────────────────────────
               에러 페이지
───────────────────────────────────────────── */

.error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: #333;
    gap: 1em;
}

.error-heading {
    font-size: 3.5em;
    font-weight: bold;
    color: var(--color-smoothgreen);
    line-height: 1em;
}

.error-subheading {
    font-size: 1.3em;
    font-weight: 400;
    color: var(--color-smoothgreen);
}

.error-paragraph {
    margin-top: 20px;
    font-size: 1em;
    color: var(--color-lightgreen);
    font-weight: 400;
}

.error-button {
    padding: 10px 20px;
    font-size: 1em;
    background-color: var(--color-lightgreen);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease;
    font-weight: bold;
}

.error-button:hover {
    background-color: var(--color-lightgreen-highlight);
}

.error-button span {
    color: var(--color-deepgreen);
}

.error-button span i {
    margin-right: 10px;
}

.error-logo-wrap {
    display: flex;
    font-size: 36px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.error-logo-wrapper i {
    font-size: 50px;
    margin-top: 6px;
}

/* ─────────────────────────────────────────────
              반응형 미디어쿼리 메인
───────────────────────────────────────────── */

/* 1250px 이상일때 */
@media (min-width: 1501px) {
    body {
        font-size: 22px !important;
    }

}

/* 1400px 이하일때 */
@media (max-width: 1400px) {
    body {
        font-size: 16px !important;
    }

    #works-list {
        padding: 40px;
    }

    .works-list-grid {
        grid-gap: 24px;
        grid-template-columns: repeat(2, 1fr)
    }

    .works-line {
        grid-gap: 24px;
    }

    .github {
        font-size: 26px;
    }
    
    .link {
        font-size: 22px;
    }
    
    .work-type {
        font-size: 14px;
    }

    .work-title {
        font-size: 16px;
    }

    .work-year {
        font-size: 16px;
    }

}

/* 800px 이하일때 */
@media (max-width: 800px) {
    body {
        font-size: 14px !important;
    }

    *::-webkit-scrollbar {
        width: 5px !important;
        height: 5px !important;
    }
    
    * ::-webkit-scrollbar-thumb {
        background-color: var(--color-lightgreen);
        border-radius: 8px;
        border: none !important;
    }
    
    * ::-webkit-scrollbar-thumb:hover {
        background-color: var(--color-lightgreen-highlight);
    }

    #works-list {
        padding: 15px;
    }

    .works-list-grid {
        grid-gap: 15px;
        grid-template-columns: repeat(1, 1fr)
    }

    .works-line {
        grid-gap: 15px;
    }

}

/* 330px 이하일때 */
@media (max-width: 330px) {
    body {
        font-size: 12px !important;
    }

}

@media (prefers-reduced-motion: reduce) {
    * {
        animation: none !important;
        transition: none !important;
        scroll-behavior: auto !important;
    }
}

/* ─────────────────────────────────────────────
              애니메이션 레퍼런스
───────────────────────────────────────────── */

.fadeInLeft {
    animation: fadeInLeft 0.5s forwards;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(20px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@-webkit-keyframes logo {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.07);
        transform: scale(1.07);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes logo {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.07);
        transform: scale(1.07);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes zoom {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

.work-video-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.work-video-container.loading {
    background: linear-gradient(135deg, #ff5733, #33ff57);
    filter: blur(10px);
    transition: filter 0.3s ease, background 0.3s ease;
}

.work-video-container.loaded {
    background: transparent;
    filter: none;
}