/* ─────────────────────────────────────────────
                  전체 초기화
───────────────────────────────────────────── */

* {
    border: 0;
    margin: 0;
    padding: 0;
    outline: 0;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    -webkit-tab-highlight-color: rgba(0, 0, 0, 0);
    vertical-align: baseline;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*::-webkit-scrollbar {
    width: 14px;
    height: 14px;
}

* ::-webkit-scrollbar-thumb { 
    background-color: var(--color-lightgreen); 
    border-radius: 8px; border: 3px solid var(--color-deepgreen); 
}

* ::-webkit-scrollbar-thumb:hover { 
    background-color: var(--color-lightgreen-highlight); 
}

html, body, h1, h2, h3, h4, h5, h6, p, blockquote, figure, dl, dd {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}

html,
body {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 18px;
    text-align: center;
    scroll-behavior: smooth;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

ol,
ul {
  list-style: none;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
    scroll-behavior: auto !important;
  }
}

img, svg {
  display: block;
  max-width: 100%;
  height: auto;
}

* {
  word-break: break-word;
}

table {
  width: 100%;
  border-collapse: collapse;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
    scroll-behavior: auto !important;
  }
}

/* ─────────────────────────────────────────────
                  공통 레퍼런스
───────────────────────────────────────────── */

:root {
    --color-deepgreen: #203940;
    --color-lightgreen-highlight: #d3f4d6;
    --color-lightgreen: #82de89;
    --color-smoothgreen: #89e8bc;
    --color-smoothgreen-light: #b8fcdc;
    --color-smoothblue: #85d1cf;
    --color-lgray: #e1e7e4;
    --color-mgray: #bdc2bf;
    --color-dgray: #929694;
    --color-black: #121212;
    --color-night: #001632;

    --color-text-span: #bbb;

    --shadow-normal: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --shadow-bold: 0 5px 15px -3px rgba(0, 0, 0, 0.23),
        0 4px 6px -2px rgba(0, 0, 0, 0.23);
        
    --works-gap: 2vw;
}

/* 드래그 허용 클래스 */
.drag {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}
